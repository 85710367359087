import "./defi_coverage.scss";

function Article({ title, description }) {
  return (
    <article className="defi_coverage__article">
      <div>
        <div className="defi_coverage__article__title">
          <h4>{title}</h4>
        </div>
        <div className="defi_coverage__article__description">
          <ul>
            {description.map((paragraph) => (
              <li key={paragraph}>{paragraph}</li>
            ))}
          </ul>
        </div>
      </div>
    </article>
  );
}

export default Article;
