import React from 'react';
import logo from './logo.png';
import './DeFi.scss';
import Section, { SectionDescription, SectionTitle } from '../Section';
import Slider from "react-slick";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500
};

function DeFi() {
  const [isMobile, setIsMobile] = React.useState(true);

  const resizeHandler = () => {
    setIsMobile(window.innerWidth < 768)
  };

  React.useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  const Wrapper = isMobile ? 'article' : Slider

  return (
    <Section light className='de-fi' id="de-fi">
      <SectionTitle className='de-fi__title'>DeFi Yields, Institutional Readiness</SectionTitle>
      <SectionDescription className='de-fi__description'>
        <p><strong>IntoTheBlock DeFi Quant</strong> strategies plug into your existing custody infrastructure allowing you complete control of the assets.</p>
      </SectionDescription>
      <Wrapper {...settings} className='de-fi__articles'>
        <article className='de-fi__article'>
          <div className='de-fi__article__logo'>
            <img src={logo} alt="" />
          </div>
          <div className='de-fi__article__title'>
            <h4>Institutional-grade Platform for DeFi Quant Strategies</h4>
          </div>
          <div className='de-fi__article__description'>
            <p>We provide a gateway for institutions to extract alpha from DeFi with the right levels of security, risk management and intelligence.</p>
          </div>
        </article>
        <article className='de-fi__article'>
          <div className='de-fi__article__logo'>
            <img src={logo} alt="" />
          </div>
          <div className='de-fi__article__title'>
            <h4>Broad Portfolio of Market Neutral Strategies</h4>
          </div>
          <div className='de-fi__article__description'>
            <p>Choose between more than 60 uncorrelated strategies covering over 20 DeFi protocols across different blockchains.</p>
          </div>
        </article>
        <article className='de-fi__article'>
          <div className='de-fi__article__logo'>
            <img src={logo} alt="" />
          </div>
          <div className='de-fi__article__title'>
            <h4>A Quant Platform, not a Hedge Fund</h4>
          </div>
          <div className='de-fi__article__description'>
            <p>DeFi Quant by IntoTheblock does not only provide access to high DeFi yields. It also enables the implementation of custom products using DeFi strategies.</p>
          </div>
        </article>
        <article className='de-fi__article'>
          <div className='de-fi__article__logo'>
            <img src={logo} alt="" />
          </div>
          <div className='de-fi__article__title'>
            <h4>Adopted by the Top Crypto Treasuries</h4>
          </div>
          <div className='de-fi__article__description'>
            <p>Our DeFi strategies are used by the top exchanges, lenders, custodians and hedge funds in the crypto market.</p>
          </div>
        </article>
        <article className='de-fi__article'>
          <div className='de-fi__article__logo'>
            <img src={logo} alt="" />
          </div>
          <div className='de-fi__article__title'>
            <h4>Native DeFi Risk Management</h4>
          </div>
          <div className='de-fi__article__description'>
            <p>Our strategies include native smart contracts to mitigate risk conditions in DeFi protocols.</p>
          </div>
        </article>
        <article className='de-fi__article'>
          <div className='de-fi__article__logo'>
            <img src={logo} alt="" />
          </div>
          <div className='de-fi__article__title'>
            <h4>Non-Custodial Solution</h4>
          </div>
          <div className='de-fi__article__description'>
            <p>A non-custodial solution where clients can withdraw funds at any time, with no management or setup fees.</p>
          </div>
        </article>
        <article className='de-fi__article'>
          <div className='de-fi__article__logo'>
            <img src={logo} alt="" />
          </div>
          <div className='de-fi__article__title'>
            <h4>Transparency and Monitoring</h4>
          </div>
          <div className='de-fi__article__description'>
            <p>The ITB DeFi quant platform provides native monitoring and visibility across all aspects of the behavior of each strategy.</p>
          </div>
        </article>
      </Wrapper>
    </Section>
  );
}

export default DeFi;
