import React from 'react';
import PropTypes from 'prop-types';
import "./TextField.scss";
import c from 'classnames';

const TextField = ({ className, block, ...rest }) => (
  <input
    className={c(className, "text-field", {
      'text-field--block': block,
    })}
    {...rest}
  />
);

TextField.defaultProps = {
  className: undefined,
};

TextField.propTypes = {
  className: PropTypes.string,
};

export default TextField;
