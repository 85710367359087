import Section, { SectionDescription, SectionTitle } from "../Section";
import Article from "./Article";
import iconPosition from "./icon-position.png";
import iconAI from "./icon-artificial-intelligence.png";
import iconPulse from "./icon-pulse.png";
import iconCrowfunding from "./icon-crowfunding.png";

function Risk() {
  return (
    <Section className="risk">
      <SectionTitle className="risk__title">Risk managed DeFi</SectionTitle>
      <SectionDescription>
        IntoTheBlock DeFi Quant platform real time risk management capabilities
        to ensure the safety of customers positions.
      </SectionDescription>
      <div className="risk__container">
        <div className="risk__articles">
          <Article
            img={iconPulse}
            title="Real-Time Risk Monitoring"
            description={[
              "Monitor dozens of signals that represent real time risk conditions in DeFi protocols.",
            ]}
          />
          <Article
            img={iconCrowfunding}
            title="Proactive Actions"
            description={[
              "Exit positions automatically based on real time risk conditions.",
            ]}
          />
          <Article
            img={iconAI}
            title="Intelligent"
            description={[
              "Our risk models are based on hundreds of simulations in DeFi protocols and regularly learn from new conditions in the market.",
            ]}
          />
        </div>
        <img className="risk__side_image" src={iconPosition} alt={"position drilldown page"} />
      </div>
    </Section>
  );
}

export default Risk;
