import React, { useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
// import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Caret } from './caret.svg';
import './Footer.scss';

const Group = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  }

  return (
    <div className={c("footer__group", "footer__group--collapsible", { "is-active": isActive })}>
      <h4 className="footer__group__title" onClick={toggleActive}>{title} <Caret className="footer__group__caret" /></h4>
      <ul className="footer__group__content">
        {children}
      </ul>
    </div>
  );
}

Group.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const Footer = () => (
  <footer className="footer">
    <div className="footer__main">
      {/* <div><Logo className="footer__copy-logo" /></div> */}
      <div className="footer__main-container">
        <Group title="IntoTheBlock">
          <li><a href={"https://www.intotheblock.com/"}>Home</a></li>
          <li><a href={"https://www.intotheblock.com/partnerships"}>Partnerships</a></li>
          <li><a href="https://resources.intotheblock.com/">Resources</a></li>
          <li><a href={"https://www.intotheblock.com/about"}>About</a></li>
        </Group>
        <Group title="Analytics">
          <li><a href={"https://www.intotheblock.com/blockchain-analytics"}>Blockchain Analytics</a></li>
          <li><a href={"https://www.intotheblock.com/price-predictions"}>Price Predictions</a></li>
          <li><a href={"https://www.intotheblock.com/defi-analytics"}>DeFi Analytics</a></li>
          <li><a href={"https://www.intotheblock.com/market-analytics"}>Market Analytics</a></li>
        </Group>
        <Group title="Support">
          <li><a href={"https://www.intotheblock.com/faq"}>Frequently Asked Questions</a></li>
          <li><a href={"https://www.intotheblock.com/privacy-policy"}>Privacy Policy</a></li>
          <li><a href={"https://www.intotheblock.com/terms-of-use"}>Terms of use</a></li>
          <li><a href={"https://www.intotheblock.com/disclaimer"}>Disclaimer</a></li>
        </Group>
        <div className="footer__group footer__group--contact">
          <Group title="Contact Us">
          </Group>
          <div className="footer__group footer__group--social">
            <h4 className="footer__group__title">Find us on</h4>
            <ul className="footer__group__content">
              <li><a href="https://www.facebook.com/intotheblock-496141757457045" target="_blank" rel="noreferrer noopener"><i className="icon-facebook" /></a></li>
              <li><a href="https://twitter.com/intotheblock" target="_blank" rel="noreferrer noopener"><i className="icon-twitter" /></a></li>
              <li><a href="https://www.linkedin.com/company/intotheblock" target="_blank" rel="noreferrer noopener"><i className="icon-linkedin" /></a></li>
              <li><a href="https://medium.com/intotheblock" target="_blank" rel="noreferrer noopener"><i className="icon-medium" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCE9jsbsVoQzR_dAz4KLupPg" target="_blank" rel="noreferrer noopener"><i className="icon-youtube" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="footer__copy">
      <div className="footer__copy-container">
        <p>&copy; {(new Date()).getFullYear()} IntoTheBlock</p>
      </div>
    </div>
  </footer>
);

export default Footer;
