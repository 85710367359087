import React from 'react';
import './Modal.scss';

const Modal = ({ active, children }) => {
  if (!active) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal__backdrop" />
      <div className="modal__content">{children}</div>
    </div>
  );
};

export default Modal;
