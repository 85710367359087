import Section, { SectionTitle } from "../Section";
import Article from "./Article";
import bubblesImage from "./bubbles.png";

function DeFiCoverage() {
  return (
    <Section className="defi_coverage">
      <div className="defi_coverage__container">
        <img
          className="defi_coverage__side_image"
          src={bubblesImage}
          alt={"position drilldown page"}
        />
        <div className="defi_coverage__articles">
          <h2 className="defi_coverage__title">Unparalleled DeFi Coverage</h2>
          <Article
            title="Over 80 quantitative strategies"
            description={[
              "A rich set of lending-borrowing, liquidity provision, multi-protocol, single-asset, and multi-asset strategies for different levels of risk appetite and capacity.",
            ]}
          />
          <Article
            title="Integration with over 40 DeFi protocols and 10 blockchains"
            description={[
              "Deep understanding and coverage of the most active protocols at any given time.",
            ]}
          />
          <Article
            title="Over 50 DeFi native risk management models"
            description={[
              "Real time monitor and proactive management of economic risk conditions in DeFi protocols.",
            ]}
          />
          <Article
            title="Wide crypto asset coverage"
            description={[
              "Stablecoins, large cap cryptos, and newer assets available as the base currencies for different strategies.",
            ]}
          />
        </div>
      </div>
    </Section>
  );
}

export default DeFiCoverage;
