import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import Spinner from '../Spinner';
import './Button.scss';

const Button = ({ children, onClick, as: Component, className, block, primary, secondary, light, outline, loading, error, disabled, ...rest }) => {
  const buttonClassName = c(className, 'button', {
    'button--block': block,
    'button--primary': primary,
    'button--secondary': secondary,
    'button--light': light,
    'button--outline': outline,
    'button--disabled': disabled,
    'button--loading': loading,
    'button--error': error,
  });

  return (
    <Component
      data-testid="button"
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled || loading}
      {...rest}
    >
      <span className="button__text">{children}</span>
      {loading && <div className="button__spinner"><Spinner /></div>}
    </Component>
  );
}

Button.defaultProps = {
  onClick: () => { },
  as: 'a',
  className: undefined,
  block: false,
  primary: false,
  secondary: false,
  light: false,
  outline: false,
  loading: false,
  error: false,
  disabled: false,
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  as: PropTypes.elementType,
  className: PropTypes.string,
  block: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  light: PropTypes.bool,
  outline: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
