import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './Section.scss';

export const SectionTopTitle = ({ children, left, className }) => (
  <h1 className={c("section__top-title", className, { "section__top-title--left": left })}>{children}</h1>
);

SectionTopTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SectionTitle = ({ children, left, className }) => (
  <h2 className={c("section__title", className, { "section__title--left": left })}>{children}</h2>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SectionDescription = ({ children, small, left, className }) => (
  <div className={c("section__description", className, { "section__description--small": small, "section__description--left": left })}>{children}</div>
);

SectionDescription.defaultProps = {
  small: false,
};

SectionDescription.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
};

export const SectionActions = ({ children, left }) => (
  <div className={c("section__actions", { "section__actions--left": left })}>{children}</div>
);

SectionActions.propTypes = {
  children: PropTypes.node.isRequired,
};

const Section = ({ children, light, primary, secondary, className, containerRef, ...rest }) => {
  return (
    <section className={c(className, "section", {
      'section--light': light,
      'section--primary': primary,
      'section--secondary': secondary
    })} ref={containerRef} {...rest}>
      <div className="container">
        {children}
      </div>
    </section>
  );
};

Section.defaultProps = {
  light: false,
  className: undefined,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
  className: PropTypes.string,
};

export default Section;
