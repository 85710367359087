import './Trusted.scss';

function Article({ img, title, description }) {
  return (
    <article className='trusted__article'>
      <div className='trusted__article__logo'>
        <img src={img} alt={title} />
      </div>
      <div className='trusted__article__title'>
        <h4>{title}</h4>
      </div>
      <div className='trusted__article__description'>
        <ul>
          {description.map(paragraph => (
            <li key={paragraph}>• {paragraph}</li>
          ))}
        </ul>
      </div>
    </article>
  );
}

export default Article;
