import { useEffect } from "react";
import Section, { SectionDescription, SectionTitle } from "../Section";
import Button from "../Button";
import { TextField, Textarea } from "../Form";
import "./TodayForm.scss";
import macbook from "./macbook-mockup.png";

function TodayForm() {
  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "5494544",
        formId: "d02b3f32-8796-41e5-90da-29ef972e3d64",
        target: "#contact-hubspot-form",
        css: "",
      });
    }
  }, []);

  return (
    <Section primary className="today-form" id="contact-us">
      <div className="today-form__body">
        <div className="today-form__mac">
          <img src={macbook} alt="" data-aos="fade-right" />
        </div>
        <div className="today-form__form" data-aos="fade-left">
          <SectionTitle>Get Started Today!</SectionTitle>
          <SectionDescription>
            <p>Have an enquiry? Fill out the form to contact our team</p>
          </SectionDescription>
          <div id="contact-hubspot-form" className="contact-form" />
        </div>
      </div>
    </Section>
  );
}

export default TodayForm;
