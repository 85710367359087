import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import DeFi from "./components/DeFi";
import DeFiCoverage from "./components/DeFICoverage";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Section from "./components/Section";
import Today from "./components/Today";
import TodayForm from "./components/TodayForm";
import Risk from "./components/Risk";
import Trusted from "./components/Trusted";
import CheckModal from "./components/CheckModal";
import "./styles/stylesheet.css";
import "./styles/scss/main.scss";
import "./styles/icomoon/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Hero />
      <main>
        <DeFiCoverage />
        <DeFi />
        <Today />
        <Risk />
        <Trusted />
        <TodayForm />
        <Section light className="disclaimer">
          <h5>Disclaimer</h5>
          <p>
            This Site and the content therein is for information purposes only
            and is not intended to and does not provide tax, legal, insurance or
            investment advice, and nothing on the Site should be construed as an
            offer to sell, a solicitation of an offer to buy, or a
            recommendation for any security by IntoTheBlock or any third party.
            You should consult an attorney or tax professional regarding your
            specific legal or tax situation. In no event shall IntoTheBlock be
            liable for any damages, costs, expenses, legal fees, or losses
            (including lost income or lost profit and opportunity costs) in
            connection with any use of the Site and the content therein. A
            reference to a particular investment or security, a rating or any
            observation concerning an investment that is part of the Site is not
            a recommendation to buy, sell or hold such investment or security,
            does not address the suitability of an investment or security and
            should not be relied on as investment advice.
          </p>
        </Section>
      </main>
      <Footer />
      <CheckModal />
    </>
  );
}

export default App;
