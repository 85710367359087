import React from 'react';
import Modal from '../Modal';
import Step1 from './Step1';
import Step2 from './Step2';
import './CheckModal.scss';

let showDefault = true;
if (typeof window !== 'undefined') {
  showDefault = !Boolean(window.localStorage.getItem("itb_qs_showModal"));
}

const CheckModal = () => {
  const [active, setActive] = React.useState(showDefault);
  const [activeStep, setActiveStep] = React.useState(1);

  const onClickIndividualHandler = (e) => {
    e.preventDefault();
    setActiveStep(2);
  }

  const onCloseHandler = (e) => {
    e.preventDefault();
    setActive(false);
  };

  const onClickCompanyHandler = (e) => {
    e.preventDefault();
    window.localStorage.setItem("itb_qs_showModal", false);
    setActive(false);
  };

  return (
    <Modal active={active}>
      {activeStep === 2 ? (
        <Step2 onClose={onCloseHandler} />
      ) : (
        <Step1 onClickIndividual={onClickIndividualHandler} onClickCompany={onClickCompanyHandler} onClose={onCloseHandler} />
      )}
    </Modal>
  );
};

export default CheckModal;
