import logo from './Logo.svg';
import './Header.scss';

function Header() {
  return (
    <header className='header'>
      <img className='header__logo' src={logo} alt="Quant by intotheblock" />
    </header>
  );
}

export default Header;
