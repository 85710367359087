import React from 'react';
import PropTypes from 'prop-types';
import "./Textarea.scss";
import c from 'classnames';

const Textarea = ({ className, block, ...rest }) => (
  <textarea
    className={c(className, "text-area", {
      'text-area--block': block,
    })}
    {...rest}
  ></textarea>
);

Textarea.defaultProps = {
  className: undefined,
};

Textarea.propTypes = {
  className: PropTypes.string,
};

export default Textarea;
