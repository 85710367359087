import Section, { SectionDescription, SectionTitle } from "../Section";
import "./Trusted.scss";
import iconCryptoexchanges from "./icon-cryptoexchanges.png";
import iconCryptolenders from "./icon-cryptolenders.png";
import iconCustodians from "./icon-custodians.png";
import iconHedge from "./icon-hedge.png";
import iconTreasuries from "./icon-treasuries.png";
import Article from "./Article";

function Trusted() {
  return (
    <Section light className="trusted">
      <SectionTitle>Trusted by the Biggest Crypto Companies</SectionTitle>
      <SectionDescription>
        Quant by IntoTheBlock is used by the top exchanges, lenders, custodians
        and hedge funds in the crypto market.
      </SectionDescription>
      <div className="trusted__articles">
        <Article
          img={iconCryptoexchanges}
          title="Crypto Exchanges"
          description={[
            "Generate attractive yields in the native assets of your treasury",
            "Build yield structured products for your institutional customers backed by a large portfolio of DeFi strategies",
          ]}
        />
        <Article
          img={iconCryptolenders}
          title="Crypto Lenders"
          description={[
            "Augment your existing institutional yields with DeFi quant strategies",
            "Complement existing OTC and CeFi yield offerings with institutional-grade DeFi yields",
          ]}
        />
        <Article
          img={iconCustodians}
          title="Custodians"
          description={[
            "Enable attractive DeFi yields for idle crypto holdings",
            "Provide an institutional-ready way to access DeFi yields tightly integrated with your custody infrastructure",
          ]}
        />
        <Article
          img={iconTreasuries}
          title="DAO Treasuries"
          description={[
            "Augment your treasury holding by accessing native DeFi yields with levels of risk management and security",
            "Natively integrate on-chain using smart contracts",
          ]}
        />
        <Article
          img={iconHedge}
          title="Hedge and VC Funds"
          description={[
            "Generate yields for idle capital",
            "Redeem funds immediately when needed without intrusive lockup periods",
          ]}
        />
      </div>
    </Section>
  );
}

export default Trusted;
