import React from 'react';
import Section from '../Section';
import Button from '../Button';
import './Today.scss'

function Today() {

  const cuHandler = () => {
    document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Section secondary className='today'>
      <div className='today__body'>
        <h3 className='today__title'>Get Started Today!</h3>
        <div className='today__actions'>
          <Button light onClick={cuHandler}>Contact Us</Button>
        </div>
      </div>
    </Section>
  );
}

export default Today;
