import React from 'react';
import individual from './assets/icons8-user-male-100-1.png';
import company from './assets/icons8-company-100-1.png';

const Step1 = ({ onClickIndividual, onClickCompany, onClose }) => {
  return (
    <div>
      <h1 className="check-modal__title">It’s great to have you here!</h1>
      <p className="check-modal__description">For a more personalized experience, please choose the profile that describes you best:</p>
      <div className="check-modal__options">
        <a href="#" onClick={onClickIndividual} className="check-modal__option">
          <span className="check-modal__option__image">
            <img alt="I’m an individual" src={individual} />
          </span>
          <span className="check-modal__option__title">I’m an individual</span>
          <span className="check-modal__option__desc">Interested in learning more about DeFi Yield Strategies.</span>
        </a>
        <a href="#" onClick={onClickCompany} className="check-modal__option">
          <span className="check-modal__option__image">
            <img alt="I represent an institution" src={company} />
          </span>
          <span className="check-modal__option__title">I represent an institution</span>
          <span className="check-modal__option__desc">Looking to leverage our current treasury solutions.</span>
        </a>
      </div>
      <div className="check-modal__footer">or <a href="#" onClick={onClose}>Skip for now</a></div>
    </div>
  );
};

export default Step1;
