import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './Spinner.scss';

const Spinner = ({ className, ...rest }) => {
  return (
    <div
      data-testid="spinner"
      className={c(className, "sk-chase")}
      {...rest}
    >
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  );
};

Spinner.defaultProps = {
  className: undefined,
};

Spinner.propTypes = {
  className: PropTypes.string,
};

export default Spinner;
