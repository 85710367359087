import React from 'react';
import Button from '../Button';

const Step2 = ({ onClose }) => {
  return (
    <div>
      <h1 className="check-modal__title">We have a better option for you...</h1>
      <p className="check-modal__description">IntoTheBlock’s Quant Services are intended for Institutions. <br />
For a retail or individual-centered experience, we recommend trying our Analytics Platform.</p>
      <Button block secondary className="check-modal__button" as="a" href="https://www.intotheblock.com/">Proceed to intotheblock.com</Button>
      <div className="check-modal__footer">or <a href="#" onClick={onClose}>Stay in this page</a></div>
    </div>
  );
};

export default Step2;
