// import './styles/scss/main.scss';
import React from "react";
import Button from "../Button";
import Section, { SectionDescription } from "../Section";
import Header from "../Header";
import dashboard from "./dashboard.gif";
import "./Hero.scss";
import Counter from "./Counter";

function Hero() {
  const [isMobile, setIsMobile] = React.useState(true);

  const resizeHandler = () => {
    setIsMobile(window.innerWidth < 768);
  };

  React.useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const startNowHandler = () => {
    document
      .getElementById("contact-us")
      .scrollIntoView({ behavior: "smooth" });
  };

  const learnMoreHandler = () => {
    document.getElementById("de-fi").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Section light className="hero">
      <div className="hero__top">
        <div className="hero__info">
          <Header />
          <h1 className="hero__title">
            Access DeFi through{" "}
            <span className="hero__title__highlight">
              smart quantitative strategies
            </span>{" "}
            and{" "}
            <span className="hero__title__highlight">
              robust risk management.
            </span>
          </h1>
          <SectionDescription>
            Leverage your institutional yields in the native asset of your
            treasury.
          </SectionDescription>
          <div className="hero__actions">
            <Button secondary onClick={startNowHandler}>
              Start Now
            </Button>
            <Button secondary outline onClick={learnMoreHandler}>
              Learn More
            </Button>
          </div>
        </div>
        <div className="hero__dashboard">
          <div className="hero__dashboard__img">
            <img src={dashboard} alt="Dashboard" />
          </div>
        </div>
      </div>
      {/* <div className="hero__counters"> DEPRECATED
        <div className="hero__counters__counter">
          <div className="hero__counters__value">
            <Counter min={0} max={20} />+
          </div>
          <p className="hero__counters__label">CLIENTS</p>
        </div>
        <div className="hero__counters__counter">
          <div className="hero__counters__value">
            <Counter min={0} max={10} time={750} />+
          </div>
          <p className="hero__counters__label">CRYPTO-ASSETS</p>
        </div>
        <div className="hero__counters__counter">
          <div className="hero__counters__value">
            <Counter min={0} max={80} time={1000} />+
          </div>
          <p className="hero__counters__label">STRATEGIES</p>
        </div>
        <div className="hero__counters__counter">
          <div className="hero__counters__value">
            <Counter min={0} max={7} />+
          </div>
          <p className="hero__counters__label">BLOCKCHAINS</p>
        </div>
        <div className="hero__counters__counter">
          <div className="hero__counters__value">
            <Counter min={0} max={20} time={1000} />+
          </div>
          <p className="hero__counters__label">DEFI PROTOCOLS</p>
        </div>
      </div> */}
    </Section>
  );
}

export default Hero;
